<template>
  <div>
    <section class="cont_01">
      <form @submit="onSearch">
        <div class="searchform_cont_01">
          <span style="margin-right: 0">
            <label>기간조회</label>
            <date-picker
              v-model="startDate"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
            <span class="ml_04 mr_04">~</span>
            <date-picker
              v-model="endDate"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
          </span>
          <span>
            <label style="opacity: 0">검색</label>
            <base-button type="primary" nativeType="submit">검색</base-button>
          </span>
        </div>
      </form>
    </section>

    <section>
      <div class="line_chart_box">
        <div class="tbl_top_left">
          <h3 class="tbl_count_title">일별 미션 완료 현황</h3>

          <span class="btn_right_mini">
            <base-button
              type="download"
              @click.prevent.stop="
                download(
                  'mission/statistics-general-complete-daily?download=xls',
                  '일별 뱃지 미션 완료 현황',
                  'xls',
                  '',
                  'mission/statistics-reward-complete-daily',
                  '일별 지자체 미션 완료 현황'
                )
              "
              :disabled="btnDisabled"
              >{{ downloadText }}</base-button
            >
          </span>
          <div class="line_chart_container">
            <div v-if="missionComplete.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!missionComplete.empty">
              <multi-line-chart
                :label="missionLabel"
                :date="missionComplete.date"
                :data="missionComplete.data"
              ></multi-line-chart>
            </div>
            <div v-else style="text-align: center; font-weight: bold">
              차트데이터가 없습니다.
            </div>
          </div>
        </div>
        <div class="tbl_top_right">
          <h3 class="tbl_count_title">일별 미션 리뷰 현황</h3>
          <span class="btn_right_mini">
            <base-button
              type="download"
              @click.prevent.stop="
                download(
                  'mission/statistics-general-review-daily?download=xls',
                  '일별 뱃지 미션 리뷰 현황',
                  'xls',
                  '',
                  'mission/statistics-reward-review-daily',
                  '일별 지자체 미션 리뷰 현황'
                )
              "
              :disabled="btnDisabled"
              >{{ downloadText }}</base-button
            >
          </span>
          <div class="line_chart_container">
            <div v-if="missionReview.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!missionReview.empty">
              <multi-line-chart
                :label="missionLabel"
                :date="missionReview.date"
                :data="missionReview.data"
              ></multi-line-chart>
            </div>
            <div v-else style="text-align: center; font-weight: bold">
              차트데이터가 없습니다.
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="pie_chart_box">
        <div class="tbl_top_left">
          <h3 class="tbl_count_title">미션 리뷰 만족도 비율</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'mission/statistics-general-review-rate-daily?download=xls',
                  '미션 리뷰 만족도 비율 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="scoreChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!scoreChart.empty">
              <pie-chart :items="scoreChart.data"></pie-chart>
            </div>
            <div v-else style="text-align: center; font-weight: bold">
              차트데이터가 없습니다.
            </div>
          </div>
        </div>
        <div class="tbl_top_right">
          <h3 class="tbl_count_title">미션 리뷰 작성률</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'mission/statistics-general-review-write-rate-daily?download=xls',
                  '미션 리뷰 작성률 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="reviewChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!reviewChart.empty">
              <pie-chart :items="reviewChart.data"></pie-chart>
            </div>
            <div v-else style="text-align: center; font-weight: bold">
              차트데이터가 없습니다.
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/**
 * vuex
 */
import { mapGetters } from "vuex";

/**
 * client
 */
import client from "api-client";

// chart
import MultiLineChart from "../../../components/chart/BaseMultiLineChart.vue";
import PieChart from "../../../components/chart/BasePieChart.vue";

import moment from "moment";
import File from "@/common/file";
import { API_URL, SERVICE_API_URL } from "@/common/config";

export default {
  name: "StampDashboard",
  components: { MultiLineChart, PieChart },
  data() {
    /**
     * data
     */
    return {
      downloadText: "엑셀 다운로드",
      btnDisabled: false,
      startDate: "",
      endDate: "",
      missionLabel: ["뱃지 미션", "지자체 미션"],
      missionComplete: {
        loading: true,
        empty: true,
        date: [],
        data: [],
      },
      missionReview: {
        loading: true,
        empty: true,
        date: [],
        data: [],
      },
      scoreChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: [
            "#f48fb1",
            "#ef9a9a",
            "#ce93d8",
            "#b39ddb",
            "#c5cae9",
            "#eee",
          ],
        },
      },
      reviewChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: ["#f48fb1", "#eee"],
        },
      },
    };
  },
  created() {
    /**
     * created
     */
    if (!this.startDate && !this.endDate) {
      this.startDate = moment(new Date()).add(-29, "day").format("YYYY-MM-DD");
      this.endDate = moment(new Date()).format("YYYY-MM-DD");
    }
    this.$Progress.start();
    // console.log(client);
    this.fetch();
  },
  mounted() {
    /**
     * mounted
     */
    this.$Progress.finish();
  },
  computed: {
    /**
     * computed
     */
    ...mapGetters(["isAuthenticated"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    /**
     * methods
     */

    download(url, name, type, params, url2, name2) {
      // this.downloadText = "다운로드 중";
      // this.btnDisabled = true;
      params = {
        page: this.currentPage,
        size: this.pageSize,
        startDate: this.$date(new Date(this.startDate)).format("YYYY-MM-DD"),
        endDate: this.$date(new Date(this.endDate)).format("YYYY-MM-DD"),
        download: "xls",
      };
      if (url2) {
        url = `${API_URL}${SERVICE_API_URL}/${url}`;
        File.download(url, name, type, params);
        url2 = `${API_URL}${SERVICE_API_URL}/${url2}`;
        File.download(url2, name2, type, params, (response) => {
          console.log(response);
          // if (response.status == 200) {
          this.btnDisabled = false;
          this.downloadText = "엑셀 다운로드";
          // }
        });
      }
      url = `${API_URL}${SERVICE_API_URL}/${url}`;
      File.download(url, name, type, params, (response) => {
        console.log(response);
        // if (response.status == 200) {
        this.btnDisabled = false;
        this.downloadText = "엑셀 다운로드";
        // }
      });
    },

    onSearch(event) {
      event.preventDefault();
      this.fetch(1);
    },

    async fetchMissionCompleteDaily() {
      let params = {
        startDate: this.$date(new Date(this.startDate)).format("YYYY-MM-DD"),
        endDate: this.$date(new Date(this.endDate)).format("YYYY-MM-DD"),
      };

      let date = [];
      let generals = [];
      let clients = [];

      await client.generalCompleteDaily(params).then(
        (response) => {
          const { data } = response;
          const items = data.items;
          generals = items.map((item) => item.cnt);
          date = items.map((item) => item.date);
        },
        () => {}
      );
      await client.rewardCompleteDaily(params).then(
        (response) => {
          const { data } = response;
          const items = data.items;
          clients = items.map((item) => item.cnt);
        },
        () => {}
      );

      this.missionComplete.date = date;
      this.missionComplete.data = [generals, clients];
      this.missionComplete.loading = false;
      this.missionComplete.empty = generals && clients ? false : true;
    },

    async fetchMissionReviewDaily() {
      let params = {
        startDate: this.$date(new Date(this.startDate)).format("YYYY-MM-DD"),
        endDate: this.$date(new Date(this.endDate)).format("YYYY-MM-DD"),
      };

      let date = [];
      let generals = [];
      let clients = [];

      await client.missionReviewDaily(params).then(
        (response) => {
          const { data } = response;
          const items = data.items;
          generals = items.map((item) => item.cnt);
          date = items.map((item) => item.date);
        },
        () => {}
      );
      await client.missionRewardReviewDaily(params).then(
        (response) => {
          const { data } = response;
          const items = data.items;
          clients = items.map((item) => item.cnt);
        },
        () => {}
      );

      this.missionReview.date = date;
      this.missionReview.data = [generals, clients];
      this.missionReview.loading = false;
      this.missionReview.empty = generals && clients ? false : true;
    },

    async fetch() {
      this.missionComplete.loading = true;
      this.missionReview.loading = true;
      this.scoreChart.loading = true;
      this.reviewChart.loading = true;
      if (!this.startDate || !this.endDate) {
        alert("날짜를 입력해주세요!");
      } else {
        let params = {
          startDate: this.$date(new Date(this.startDate)).format("YYYY-MM-DD"),
          endDate: this.$date(new Date(this.endDate)).format("YYYY-MM-DD"),
        };

        this.fetchMissionCompleteDaily();
        this.fetchMissionReviewDaily();

        await client.missionReviewRateDaily(params).then(
          (response) => {
            const { data } = response;
            if (data.items) {
              this.scoreChart.data.label = data.items.map((item) => item.label);
              this.scoreChart.data.value = data.items.map((item) => item.value);
              this.scoreChart.loading = false;
              this.scoreChart.empty = false;
            } else {
              this.scoreChart.loading = false;
              this.scoreChart.empty = true;
            }
          },
          () => {
            this.scoreChart.loading = false;
            this.scoreChart.empty = true;
          }
        );
        await client.missionReviewWriteRateDaily(params).then(
          (response) => {
            const { data } = response;
            if (data.items) {
              this.reviewChart.data.label = data.items.map(
                (item) => item.label
              );
              this.reviewChart.data.value = data.items.map(
                (item) => item.value
              );
              this.reviewChart.loading = false;
              this.reviewChart.empty = false;
            } else {
              this.reviewChart.loading = false;
              this.reviewChart.empty = true;
            }
          },
          () => {
            this.reviewChart.loading = false;
            this.reviewChart.empty = true;
          }
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
@import "../../../assets/scss/common.scss";
</style>
